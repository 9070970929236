import {BrowserRouter, Route} from "react-router-dom";
import {Container} from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import {AppRoutesPublic} from "./App.Routes.Public";
import {AppRoutesUser} from "./App.Routes.User";
import {AppRoutesSponsor} from "./App.Routes.Sponsor";
import {AppRoutesAdmin} from "./App.Routes.Admin";
import {Header as HeaderSponsor} from "./Components/Sponsor/Header";
import {Header as HeaderAdmin} from "./Components/Admin/Header";
import {Header as HeaderPublic} from "./Components/Public/Header";
import {Header as HeaderUser} from "./Components/User/Header";
import {blue, lightBlue, deepOrange, orange, green, teal} from "@mui/material/colors";
import {store} from 'state-pool';
import {LOCALSTORAGE_TOKEN_USER, LOCALSTORAGE_TOKEN_SPONSOR, LOCALSTORAGE_TOKEN_ADMIN} from "./Constants";
import {IndexPage} from "./Pages/Public/IndexPage";
import { HelmetProvider } from 'react-helmet-async';

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: lightBlue,
  },
});

const themeSponsor = createTheme({
  palette: {
    primary: teal,
    secondary: green,
  },
});

const themeAdmin = createTheme({
  palette: {
    primary: deepOrange,
    secondary: orange,
  },
});

const App: React.FC = () => {

  let loggedInSponsor = false;
  let loggedInAdmin = false;
  let loggedInUser = false;

  if (localStorage.getItem(LOCALSTORAGE_TOKEN_USER)) {
    loggedInUser = true
  }
  if (localStorage.getItem(LOCALSTORAGE_TOKEN_SPONSOR)) {
    loggedInSponsor = true
  }
  if (localStorage.getItem(LOCALSTORAGE_TOKEN_ADMIN)) {
    loggedInAdmin = true
  }

  store.setState("loggedInUser", loggedInUser);
  store.setState("loggedInSponsor", loggedInSponsor);
  store.setState("loggedInAdmin", loggedInAdmin);

  const helmetContext = {};

  return (
    <BrowserRouter>
      <HelmetProvider context={helmetContext}>
      <Container component="main" style={{maxWidth: "100%", margin: "0px", padding: "0px"}}>
        <ThemeProvider theme={theme}>
          <Route exact={true} path="/">
            <HeaderPublic/>
            <div>
              <IndexPage/>
            </div>
          </Route>
          <Route path="/public">
            <HeaderPublic/>
            <div>
              <AppRoutesPublic/>
            </div>
          </Route>
          <Route path="/users">
            <HeaderUser/>
            <div>
              <AppRoutesUser/>
            </div>
          </Route>
        </ThemeProvider>

        <ThemeProvider theme={themeSponsor}>
          <Route path="/sponsor">
            {/* ログイン画面の高さを100%にしている */}
              <div className="flex flex-col h-screen">
                <HeaderSponsor />
                <div className="flex-1">
                  <AppRoutesSponsor />
                </div>
              </div>
            </Route>
        </ThemeProvider>

        <ThemeProvider theme={themeAdmin}>
          <Route path="/admin">
            <HeaderAdmin/>
            <div>
              <AppRoutesAdmin/>
            </div>
          </Route>
        </ThemeProvider>

      </Container>
      </HelmetProvider>
    </BrowserRouter>
  );
};

export default App;
