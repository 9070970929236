import { Hub } from "Types/App"
import { Link } from 'react-router-dom'

const HubCard = ({ hub }: { hub: Hub }) => {
  const {
    id,
    name,
    state,
    city,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    image,
  } = hub

  return (
    <div className="flex bg-white rounded-lg border border-gray-200 h-32" >
      <div className="flex items-center justify-center p-4">
        <Link to={`/public/hubs/${id}`}>
          <img loading="lazy" className="h-10 w-10 rounded-full object-cover" src={image} alt={name} />
        </Link>
      </div>
      <div className="w-1/2 p-3 md:p-4 flex flex-col justify-around">
          <h3 className="text-base font-medium text-gray-900 whitespace-nowrap overflow-hidden" style={{textOverflow: "ellipsis"}}>
            <Link to={`/public/hubs/${id}`}>
              {name}
            </Link>
          </h3>
        <div>
          <p className="whitespace-normal text-sm text-gray-600 dark:text-gray-400">{state}{city}{addressLine1} {addressLine2}</p>
        </div>
      </div>
    </div>


  )
}

export default HubCard
