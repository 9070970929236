import { Coupon } from "Types/App"
import { Link } from 'react-router-dom'

const CouponCard = ({ coupon }: { coupon: Coupon }) => {
  const {
    title,
    id,
    image,
    hub_id: hubId,
    hub_name: hubName,
    hub_image: hubImage,
    hub_state: hubState,
    hub_city: hubCity,
  } = coupon

  return (
    <div className="relative bg-white border border-gray-200 rounded-lg flex flex-col overflow-hidden w-full h-full">
      <div className="bg-gray-200 aspect-none">
        <Link to={`/public/coupons/${id}`}>
          <img
            loading="lazy"
            src={image}
            alt={title}
            className="w-full h-32 object-center object-cover sm:w-full sm:h-32"
          />
        </Link>
      </div>
      <div className="flex-1 p-4 space-y-2 flex flex-col">
        <h3 className="text-base font-medium text-gray-900 truncate">
          <Link to={`/public/coupons/${id}`}>
            {title}
          </Link>
        </h3>
        <div className="flex-1 flex flex-col justify-end">
          <p className="text-sm mt-2 truncate">
            <img className="inline object-cover w-6 h-6 mr-2 rounded-full" src={hubImage} alt={hubName} />
            <Link to={`/public/hubs/${hubId}`}>
              {hubName}
            </Link>
          </p>
          <p className="text-sm text-gray-500 whitespace-normal mt-2 truncate">
            {hubState}{hubCity}
          </p>
        </div>
      </div>
    </div>
  )
}

export default CouponCard
