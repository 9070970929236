import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, ContactShadows, Html } from '@react-three/drei';
import { Suspense } from 'react';
import Model from 'Components/Public/BeeModel';
import { useWindowDimensions } from '../../utils';

 const BeeScene = () => {
  const YAngle = Math.PI / 2.5;
  const { width,  } = useWindowDimensions()

  return (
    <div className='absolute h-full w-full'>
      <Canvas
        shadows // shadows annotation is required to render shadow
      >
        <Suspense fallback={
          <Html className='w-96 h-32'>
            <h1 className='text-2xl text-white font-bold'>よみこみちゅう...</h1>
          </Html>
        }>
          <PerspectiveCamera position={[10, 0, 0]} />
          {width >= 600 &&
          <OrbitControls enablePan={false} enableZoom={false} minPolarAngle={YAngle} maxPolarAngle={YAngle} />
          }
          <ambientLight intensity={.7}/>
          {/* position
          X: positive to place this side
          Y: positive to place higher
          Z: positive to place left hand side
           */}
          <spotLight intensity={1} angle={.5} penumbra={1} position={[10, 4, -7]} castShadow />
          {/* work around of the bug  where the primitive couldn't be adjusted somehow. */}
          <group scale={0.8} position={[0, -1, 0]} rotation={[0, Math.PI / 4, 0]}>
            <Model></Model>
          </group>
          <ContactShadows scale={8} position={[0, -2, 0]} opacity={0.3} blur={3} far={7} />
        </Suspense>
      </Canvas>
    </div>
  )
}
export default BeeScene;