import BeeModel from 'Components/Public/BeeScene';

const HeroArea = ({ getLocation }: { getLocation: () => Promise<void> }) => (
  <>
    <div className="bg-image_color relative">
      <div className="absolute top-24 md:top-10 w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <BeeModel></BeeModel>
      </div>
      <div className="relative z-10 max-w-7xl w-full pt-12 text-center lg:pt-24 lg:text-left pb-24 lg:pb-24">
        <div className="lg:ml-32 px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <p className="whitespace-pre mx-auto font-playball block mt-6 text-xl mb-4 text-white sm:text-3xl md:text-3xl lg:text-4xl xl:text-3xl">
           <span className="text-2xl">\</span>  Bee Happy  <span className="text-2xl">/</span>
          </p>
          <div className='flex justify-center lg:justify-start'>
            <h1 className="text-7xl font-playball font-bold text-white sm:text-8xl md:text-8xl lg:text-9xl xl:text-9xl">
              Boomin
            </h1>
            <img src="/static/img/bee.png" alt="bee" className='w-14 h-14' />
          </div>
          <p className="mt-5 max-w-md mx-auto text-lg text-white sm:text-xl md:max-w-3xl">
            ローカルクーポンをゲットして、寄り道をもっと楽しく。
          </p>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <a href="/#map">
                <button
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondary hover:bg-secondary_darker md:py-4 md:text-lg md:px-10"
                  style={{ transition: "all 0.2s ease 0s" }}
                  onClick={getLocation}
                >
                  現在地から探す
                </button>
              </a>
            </div>
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <a href="/#map">
                <button
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-secondary bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                  style={{ transition: "all 0.2s ease 0s" }}
                >
                  地図から探す
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default HeroArea