import { FormEvent, useState } from "react";
import AddToFavorite from 'Components/User/AddToFavorite'
import Breadcrumbs from "Components/Breadcrumbs"
import { Coupon, Hub } from "Types/App";
import { ExclamationCircleIcon, ClockIcon, MapPinIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import LoginModal from "Components/User/LoginModal"
import { ToLogin } from '../Common';
import { Link } from 'react-router-dom'

type Handle = (e: FormEvent) => Promise<void>

const UseButton = ({ handleUse, loggedIn, setIsShow, available}: { handleUse: Handle, loggedIn: boolean, setIsShow: React.Dispatch<React.SetStateAction<boolean>>, available: boolean}) => {
  if (!loggedIn) {
    return (
      <button
        type="submit"
        onClick={() => ToLogin(setIsShow)}
        className="w-full flex-1 bg-primary border border-transparent rounded-md p-3 flex items-center justify-center text-base font-medium text-white hover:bg-primary_darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary sm:w-full"
      >
        使用する
      </button>
    )
  }
  if (!available) {
    return (
      <button
        type="button"
        className="w-full flex-1 bg-primary border border-transparent rounded-md p-3 flex items-center justify-center text-base font-medium text-white hover:bg-primary_darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary sm:w-full"
      >
        使用済み
      </button>
    )
  }
  return (
    <button
      type="submit"
      onClick={handleUse}
      className="w-full flex-1 bg-primary border border-transparent rounded-md p-3 flex items-center justify-center text-base font-medium text-white hover:bg-primary_darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary sm:w-full"
    >
      使用する
    </button>
  )
}

const CouponRedeemArea = ({ coupon, hub, handleUse, loggedIn, available }: { coupon: Coupon, hub: Hub, handleUse: Handle, loggedIn: boolean, available: boolean }) => {
  const [isShow, setIsShow] = useState(false);
  if (!coupon.id || !hub.id) {
    return null
  }

  const expiryDate = new Date(coupon.period_end);
  const expiry = `${expiryDate.getFullYear()}年${expiryDate.getMonth()+1}月${expiryDate.getDate()}日まで`

  const breadcrumbs = [
    { name: hub.name, href: `/public/hubs/${hub.id}`, current: false },
    { name: coupon.title, href: `/public/coupons${coupon.id}`, current: true },
  ]

  return (
    <div>
      <LoginModal isShow={isShow} setIsShow={setIsShow}></LoginModal>
      <main className="pb-12">

        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 lg:py-8">
          <div className="max-w-2xl mx-auto lg:max-w-none">
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
              <div className="w-full">
                <img src={coupon.image} alt={coupon.title} className="w-full object-top object-contain sm:rounded-lg" />
              </div>

              <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">{coupon.title}</h1>

                <div className="mt-3">
                  <img className="inline object-cover w-6 h-6 mr-2 rounded-full" src={hub.image} alt={hub.name} />
                  <Link to={`/public/hubs/${hub.id}`}>
                    {hub.name}
                  </Link>
                </div>

                <div className="mt-3">
                  <div className="text-base text-gray-700 space-y-6 whitespace-pre-wrap">
                    {coupon.description}
                  </div>
                </div>

                <div className="mt-3">
                  <div className="text-sm text-gray-400 space-y-6">
                    <ClockIcon className="w-5 h-5 inline-block mr-3" />
                    {expiry}
                  </div>
                </div>

                <div className="mt-6 flex flex-col">
                  <UseButton handleUse={handleUse} setIsShow={setIsShow} loggedIn={loggedIn} available={available} />
                  <AddToFavorite couponId={coupon.id} setIsShow={setIsShow} />
                </div>

                <div className="mt-6">
                  <div className="text-base text-gray-500 space-y-6">
                    <ExclamationCircleIcon className="w-5 h-5 inline-block mr-3" />
                    {loggedIn ? "レジでお支払いの前に「使用する」を押して、次の画面を提示してください。" : "クーポンを使用するにはアカウント作成またはログインが必要です。「使用する」から手続きを行なってください。"}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-12">
              <iframe
                title="Google Maps"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCQvbBiefRM03FZojjOhLfCAJ6MDoEIgaQ&language=ja&q=${hub.latitude},${hub.longitude}&zoom=16`}
                className="h-96 w-full mt-2"
                loading="lazy"
              />
            </div>

            <div className="px-4 sm:px-0 mt-3">
              <div className="text-base text-gray-700 space-y-6">
                <MapPinIcon className="w-5 h-5 inline-block mr-2" />
                {hub.state}{hub.city}{hub.address_line_1} {hub.address_line_2} (<a href={`https://maps.google.com/maps?q=${hub.latitude},${hub.longitude}&zoom=16`} rel="noopener noreferrer nofollow" target="_blank">Google マップ <ArrowTopRightOnSquareIcon className="w-5 h-5 inline-block" /></a>)
              </div>
            </div>
          </div>
        </div>
      </main>
        <div className="max-w-2xl mx-auto px-4 sm:px-0 lg:max-w-7xl md:px-0 lg:px-8">
          <Breadcrumbs pages={breadcrumbs} />
        </div>
    </div>
  )
}

export default CouponRedeemArea;