import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Profile } from "Components/User/Profile"
import { Favorite } from "Components/User/MyCoupon/Favorite"
import { UsedList } from "Components/User/MyCoupon/UsedList"
import { http } from "../../http";
import { API_BASE_URL } from "Constants";
import {
  HeartIcon,
  UserCircleIcon,
  TicketIcon,
} from '@heroicons/react/24/outline'

const navigation = [
  { name: 'プロフィール', code: 'profile', icon: UserCircleIcon },
  { name: 'お気に入り', code: 'favorite', icon: HeartIcon },
  { name: 'クーポン利用履歴', code: 'used_coupon', icon: TicketIcon },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface State {
  state: {
    tab: string
  }
}

interface FavoriteCountResponse {
  favorite_count: number,
}

export default function Example() {
  const [favCount, setFavCount] = useState(0);
  const state: State = useLocation();
  const [openTab, setOpenTab] = useState<string>("");
  // もしstateが渡されていなかった場合
  if(openTab === "" && state.state === undefined) {
    // プロファイルページに飛ばすためにprofileをセットする(デフォルト値)
    setOpenTab("profile")
  } else if (openTab === "" && state.state !== undefined) {
    setOpenTab(state.state.tab)
  }

  useEffect(() => {
    const getFavoriteCount = async () => {
      try {
        const response = await http<FavoriteCountResponse>(`${API_BASE_URL}/users/coupons/favorites/count`, "GET");
        const body = response.parsedBody
        if (!body) {
          return
        }
        setFavCount(body.favorite_count)
      } catch (ex: any) {
      } finally {
      }
    };
    getFavoriteCount()
    //User/Header.tsxコンポーネントがstate.state.tabを変更したことを検知してre-renderするためにこれが必要
    if (state.state !== undefined) {
      setOpenTab(state.state.tab)
    }
  }, [state.state])

  return (
    <>
      <div>
        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-56 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className='mt-1 ml-6'>
                <Link to="/">
                  <span className="font-playball text-3xl font-bold text-image_color">
                    Boomin'
                  </span>
                </Link>
              </div>
              <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
                {navigation.map((item) => (
                  <div
                    key={item.name}
                    onClick={() => setOpenTab(item.code)}
                    className={classNames(
                      item.code === openTab ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group flex items-center cursor-pointer px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.code === openTab ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6 cursor-pointer'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                    {
                      item.code === "favorite" &&
                      <div className='ml-1 px-2 text-center rounded bg-gray-200'>
                        {favCount}
                      </div>
                    }
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-56 flex flex-col flex-1">
          <main className="flex-1">
            <div className="py-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">マイページ</h1>
              </div>
              <div className="max-w-7xl mx-auto sm:px-6 md:px-8">
                {/* Replace with your content */}
                <div className="py-4">
                  { openTab === "" && <Profile></Profile> }
                  { openTab === "profile" && <Profile></Profile> }
                  { openTab === "favorite" && <Favorite></Favorite> }
                  { openTab === "used_coupon" && <UsedList></UsedList> }
                </div>
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
