import { http } from "../../http";
import { API_BASE_URL } from "Constants"
import { Coupon } from "Types/App";
import { ToLogin } from "../../Components/Common";

const FavoriteButton = ({ couponId, setIsShow }: { couponId: Coupon["id"], setIsShow: React.Dispatch<React.SetStateAction<boolean>> }) => {

  const handleFav = async () => {
    try {
      await http(API_BASE_URL + `/users/coupons/favorites/add`, "POST", {
        "coupon_id": couponId,
      })
      alert("お気に入りに追加しました")
    } catch (ex: any) {
      if (ex.toString() === "need login") {
        ToLogin(setIsShow);
        return
      }
      alert(ex.toString())
    } finally {
    }
  }
  return (
    <button
      type="button"
      className="w-full py-3 px-3 rounded-md flex items-center justify-center text-gray-500 hover:bg-gray-100 hover:text-gray-500"
      onClick={handleFav}
    >
      お気に入りに追加
    </button>
  )
}

export default FavoriteButton;
