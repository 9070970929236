import { FC } from "react";
import { useGlobalState } from "state-pool";
import { HomeIcon, PaperClipIcon } from '@heroicons/react/24/solid'
import Head from "Components/Head"
import Footer from "Components/Footer"

type IndexPageProps = {}

export const IndexPage: FC<IndexPageProps> = (props) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [loggedIn,] = useGlobalState("loggedInSponsor");
  return (
    <>
      <Head title="Boomin' にクーポンを掲載する"/>

      {loggedIn ?
        <>
          <div className="container mx-auto py-10 pt-20 sm:pt-10 pb-30 sm:pb-0">
            <h1 className="mb-20 mt-5 text-center text-4xl text-gray-600 font-black" style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
              加盟店ダッシュボード
            </h1>
            <div className="flex flex-col sm:grid sm:grid-cols-2 sm:gap-8">
              <a href="/sponsor/hubs">
                <div className="border rounded flex flex-col items-center py-10 shadow mb-10 sm:mb-0">
                  <HomeIcon className="w-1/3 h-1/3 text-secondary mb-3" />
                  <h2 className="text-center text-3xl text-gray-600 font-black" style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                    店舗一覧
                  </h2>
                </div>
              </a>
              <a href="/sponsor/hubs/coupon/used">
                <div className="border rounded flex flex-col items-center py-10 shadow">
                  <PaperClipIcon className="w-1/3 h-1/3 text-secondary mb-3" />
                  <h2 className="text-center text-3xl text-gray-600 font-black" style={{ fontFamily: "'M PLUS 1p', sans-serif" }}>
                    クーポン使用状況
                  </h2>
                </div>
              </a>
            </div>
          </div>
        </>
        :
        <div className="bg-white">
          <div className="py-10 px-10 max-w-7xl mx-auto">
            <div className="flex flex-col mb-4 items-center mx-auto h-full bg-white">
              <p className="whitespace-pre font-playball block mb-4 text-xl text-image_color sm:text-3xl md:text-3xl lg:text-4xl xl:text-3xl">
                <span className="text-2xl">\</span>  Bee Happy  <span className="text-2xl">/</span>
              </p>
              <div className='flex justify-center'>
                <h1 className="text-7xl font-playball font-bold text-image_color sm:text-8xl md:text-8xl lg:text-9xl xl:text-9xl">
                  Boomin
                </h1>
                <img src="/static/img/bee.png" alt="Bee Happy Boomin'" className='w-14 h-14' />
              </div>
            </div>
            <div className="container h-full mx-auto pb-10 sm:pt-10 pb-30 sm:pb-0">
            <img className="h-96 w-full object-cover object-bottom" src="/static/img/sponsor_appeal_map.jpg" alt="sponsor map"/>
            <div className="bg-gray-600 md:rounded-lg h-24 flex items-center justify-center mt-10">
              <h2 className="text-white text-3xl">
                Boomin' に掲載するメリット
              </h2>
            </div>
              <div className="relative">
                <div className="flex md:mt-7 relative">
                  <div className="md:rounded-full w-16 h-24 md:h-16 bg-gray-600 flex items-center justify-center absolute z-10">
                    <span className="text-white text-3xl">1</span>
                  </div>
                  <div className="md:rounded-full h-24 md:h-16 bg-image_color flex justify-start items-center absolute md:left-2 md:top-5 pr-20">
                    <p className="text-white text-xl ml-20">無料で簡単掲載! (掲載料完全無料)</p>
                  </div>
                </div>
                <div className="flex mt-32 md:mt-24 relative">
                  <div className="md:rounded-full w-16 h-24 md:h-16 bg-gray-600 flex items-center justify-center absolute z-10">
                    <span className="text-white text-3xl">2</span>
                  </div>
                  <div className="md:rounded-full h-24 md:h-16 bg-image_color flex justify-start items-center absolute md:left-2 md:top-5 pr-20">
                    <p className="text-white text-xl ml-20">お店の近くにいるユーザーにクーポンが届く！</p>
                  </div>
                </div>
                <div className="flex mt-32 md:mt-24 relative">
                  <div className="md:rounded-full w-16 h-24 md:h-16 bg-gray-600 flex items-center justify-center absolute z-10">
                    <span className="text-white text-3xl">3</span>
                  </div>
                  <div className="md:rounded-full h-24 md:h-16 bg-image_color flex justify-start items-center absolute md:left-2 md:top-5 pr-20">
                    <p className="text-white text-xl ml-20">オンライン・オフライン共に24時間常に、ユーザーにアピールができる!</p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-600 lg:rounded-lg h-24 flex items-center justify-center mt-32">
                <h2 className="text-white text-3xl">
                  クーポンご掲載方法
                </h2>
              </div>
              <div className="grid grid-cols-1 gap-8 md:grid-cols-3 place-items-center mt-10">
                <div className="bg-white text-center w-full md:border border-image_color rounded-t md:shadow">
                  <div className="bg-image_color h-10 text-white flex items-center justify-center mb-5">
                    step
                    <span className="text-2xl ml-1">1</span>
                  </div>
                  新規登録をする
                  <img className="w-40 h-40 mx-auto" src="/static/img/sponsor_appeal_1.png" alt="register" />
                </div>
                <div className="bg-white text-center w-full md:border border-image_color rounded-t md:shadow">
                  <div className="bg-image_color h-10 text-white flex items-center justify-center mb-5">
                    step
                    <span className="text-2xl ml-1">2</span>
                  </div>
                  店舗情報を入力する
                  <img className="w-40 h-40 mx-auto" src="/static/img/sponsor_appeal_2.png" alt="enter the shop info" />
                </div>
                <div className="bg-white text-center w-full md:border border-image_color rounded-t md:shadow">
                  <div className="bg-image_color h-10 text-white flex items-center justify-center mb-5">
                    step
                    <span className="text-2xl ml-1">3</span>
                  </div>
                  クーポン情報を入力する
                  <img className="w-40 h-40 mx-auto" src="/static/img/sponsor_appeal_3.png" alt="enter the coupon info" />
                </div>
              </div>
            </div>

            <div className="text-center mt-10">
              <a href="/sponsor/login" className="whitespace-nowrap text-base font-medium text-gray-700 hover:text-gray-400">
                ログイン
              </a>
              <a
                href="/sponsor/signup"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:bg-secondary"
              >
                新規登録
              </a>
            </div>
          </div>
        </div>
      }
      <Footer />
    </>
  )
};
