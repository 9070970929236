import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import {
  HeartIcon,
  UserCircleIcon,
  Bars3Icon,
  XMarkIcon,
  TicketIcon,
} from '@heroicons/react/24/outline'
import { useLocation } from 'react-router-dom'

const navigation = [
  { name: 'プロフィール', code: 'profile', icon: UserCircleIcon },
  { name: 'お気に入り', code: 'favorite', icon: HeartIcon },
  { name: 'クーポン利用履歴', code: 'used_coupon', icon: TicketIcon },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface State {
  state: {
    tab: string
  }
}

export const Header = () => {
  const state: State = useLocation();
  const [openTab, setOpenTab] = useState<string>("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  if(openTab === "" && state.state === undefined) {
    setOpenTab("profile")
  } else if (openTab === "" && state.state !== undefined) {
    setOpenTab(state.state.tab)
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed right-0 top-0 bottom-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="-translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="-translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto w-56">
                  <div className="flex justify-center lg:w-0 lg:flex-1">
                    <Link to="/">
                      <span className="font-playball text-xl font-bold text-image_color">
                        Boomin'
                      </span>
                    </Link>
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={{pathname: '/users/profile', state: {tab: item.code}}}
                        onClick={() => {setOpenTab(item.code); setSidebarOpen(false)}}
                        className={classNames(
                          item.code === openTab
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.code === openTab ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>

        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky flex justify-between top-0 z-10 md:hidden pl-1 py-4 sm:pl-3 sm:pt-3 bg-white">
            <Link to="/">
              <span className="font-playball ml-3 text-3xl font-bold text-image_color">
                Boomin'
              </span>
            </Link>
            <button
              type="button"
              className="mr-3 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-secondary"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}